<template>
  <v-container>
    <WebsocketData :url-input="[websocketURL]">
      <template slot-scope="{outData}">
        <v-row v-if="outData[websocketURL]">
          <v-col v-for="fieldName in params">
            <p> {{outData[websocketURL][fieldName]}}</p>
          </v-col>
        </v-row>
      </template>
    </WebsocketData>
  </v-container>
</template>

<script>
import WebsocketData from "../components/WebsocketData";
export default {
  name: "StreamData",
  props: ['username'],
  components: {WebsocketData},
  computed: {
    params: function(){
      if ('fields' in this.$route.query) {
        return this.$route.query['fields'].split(',')
      }
      return []
    },
    websocketURL: function(){
      return this.$route.params.username+'/singleClient'
    }
  }
}
</script>

<style scoped>
  .col *{
    text-align: center;
  }
</style>
